<template>
  <div class="userList">
    <div class="orderHeadBox">
      <van-tabs v-model="tab" @click="clickTab" type="card">
        <van-tab title="全部"></van-tab>
        <van-tab title="待付款"></van-tab>
        <van-tab title="已付款"></van-tab>
        <van-tab title="已发货"></van-tab>
        <van-tab title="已完成"></van-tab>
      </van-tabs>
    </div>
    <div class="phoneUserList" v-if="tableData && tableData.length !== 0">
      <div class="phoneBox" v-for="x in tableData" :key="x.id">
        <div class="header">
          <span>订单号：{{ x.orderId }}</span>
          <em :class='x.orderStatus == "0"
            ? "c_red"
            : x.orderStatus == "1"
              ? "c_yellow"
              : x.orderStatus == "2"
                ? "c_blue"
                : "c_green"'>{{
        x.orderStatus == "0"
        ? "待付款"
        : x.orderStatus == "1"
          ? "已付款"
          : x.orderStatus == "2"
            ? "已发货"
            : "已完成"
      }}</em>
        </div>
        <div class="userContent">
          <div class="spList">
            <div v-for="item in x.spList" :key="item.id" class="box">
              <img :src="item.coverImg.split(';')[0]" alt="" />
              <div class="info">
                <div class="name">{{ item.name }}</div>
                <div class="priceBox">
                  X{{ item.num }}
                  <span>￥{{ parseFloat(item.price) / 100 }}</span>
                </div>
                <div class="priceBox">
                  运费
                  <span>￥{{ parseFloat(item.kdf) / 100 }}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="allPrice">
            订单总价:<span style="color: #db2c2c">￥{{ parseFloat(x.account) / 100 }}</span>
          </div>
          <div class="shouhuoBox">
            <div class="line">
              <span>下单时间：{{ x.createTime | formatTime("YMDHMS") }}</span>
            </div>
          </div>
          <div class="shouhuoBox">
            <div class="line">
              <span>收货人：{{ x.addressFullName }}</span>
              <em>手机号码：{{ x.addressPhoneNumber }}</em>
            </div>
          </div>
          <div class="shouhuoBox">
            <div class="line">
              <span>下单人：{{ x.userInfo.nickName }}</span>
              <em>真实名称：{{ x.userInfo.realName }}</em>
            </div>
            <div class="line">
              <span>手机号码：{{ x.userInfo.phone }}</span>
              <em>订单类型：{{
                x.addressType == "0"
                ? "自提"
                : x.addressType == "1"
                  ? "快递"
                  : ""
              }}</em>
            </div>
            <div class="line" v-if="x.addressType == '0'">
              <span>取货码：{{ x.qhm }}</span>
            </div>
            <div class="line" v-if="x.addressType == '0'">
              <span>物流单号：{{ x.ydh }}</span>
            </div>
            <div class="line">
              <span>订单地址：{{ x.address }}</span>
            </div>
          </div>
          <div class="btnBox">
            <el-button type="primary" size="small" @click="fahuo(x.orderId, x.ydh)"
              v-if="x.orderStatus == '1'">发货</el-button>
            <el-button type="primary" size="small" @click="fahuo(x.orderId, x.ydh)"
              v-if="x.orderStatus == '2'">修改运单号</el-button>
            <el-button type="primary" size="small" @click="compeleteOrder(x.orderId)"
              v-if="x.orderStatus == '2'">完成</el-button>
          </div>
        </div>
      </div>
    </div>
    <div class="phoneUserList" v-else>
      <div class="noData">暂无数据</div>
    </div>
    <OrderDialog ref="dialog" @search="initPage" />
  </div>
</template>

<script>
import OrderDialog from "./components/dialog.vue";
import config from "@/assets/api/config.js";
export default {
  components: { OrderDialog },
  data() {
    return {
      config,
      page: {
        page: 1,
        pageSize: 1000,
      },
      params: {},
      total: 0,
      tableData: [],
      tab: '0'
    };
  },
  methods: {
    clickTab(tab) {
      console.log(tab);
      if (tab == '0') {
        this.params.orderStatus = '';
      } else {
        tab = parseFloat(tab) - 1;
        this.params.orderStatus = tab
      }
      this.initPage();
    },
    fahuo(id, ydh) {
      //发货
      this.$refs.dialog.form.id = id;
      this.$refs.dialog.form.ydh = ydh;
      this.$refs.dialog.visible = true;
    },
    compeleteOrder(id) {
      //已完成订单
      this.$confirm("确定已完成？").then(() => {
        this.getAjax(
          "api/order/compelete",
          {
            id,
          },
          "post"
        ).then((res) => {
          console.log(res);
          this.$message.success("操作成功");
          this.initPage();
        });
      });
    },
    indexMethod(index) {
      return index + 1 + (this.page.page - 1) * this.page.pageSize;
    },
    handleSizeChange(pageSize) {
      this.page.pageSize = pageSize;
      this.initPage();
    },
    handleCurrentChange(page) {
      this.page.page = page;
      this.initPage();
    },
    search(opt) {
      //搜索
      this.params = opt;
      this.initPage();
    },
    initPage() {
      this.getAjax(
        "api/order/list",
        { ...this.params, ...this.page },
        "get"
      ).then((res) => {
        this.tableData = res.data.list.records;
        this.total = res.data.list.total;
      });
    },
    add() {
      this.$refs.dialog.visible = true;
    },
    editData(item) {
      this.$refs.dialog.getInfo(item.id);
    },
  },
  mounted() {
    this.initPage();
  },
};
</script>

<style lang="less" scoped>
.userList {
  display: flex;
  flex-direction: column;
  height: 100%;

  .orderHeadBox {
    height: 40px;
  }
}

.spList {
  padding: 0 15px;

  .box {
    display: flex;
    align-items: center;
    border-bottom: 1px dashed #ccc;
    padding: 10px 0;

    &:last-child {
      border-bottom: 0;
    }

    img {
      width: 30px;
      height: auto;
      display: inline-block;
    }

    .info {
      flex: 1;
      text-align: left;
      margin-left: 10px;

      .name {
        font-size: 12px;
      }

      .priceBox {
        display: flex;
        align-items: center;

        span {
          margin-left: 20px;
          color: #db2c2c;
        }
      }
    }
  }
}

.phoneUserList {
  flex: 1;
  overflow-y: auto;

  .phoneBox {
    padding: 15px;
    margin: 15px;
    background-color: #fff;
    box-shadow: 0 0 5px #ddd;
    border-radius: 8px;

    .header {
      display: flex;
      border-bottom: 1px solid #ddd;
      padding-bottom: 10px;

      span {
        flex: 1;
      }

      em {
        color: #999;
      }
    }

    .userContent {
      padding: 10px 0;

      .allPrice {
        border-top: 1px solid #ddd;
        padding: 10px;
        text-align: right;
      }

      .shouhuoBox {
        .line {
          display: flex;

          span {
            flex: 1;
          }

          em {
            flex: 1;
          }
        }
      }

      .btnBox {
        border-top: 1px solid #ddd;
        padding-top: 10px;
        margin-top: 10px;
        display: flex;
        justify-content: flex-end;
      }
    }
  }
}</style>
